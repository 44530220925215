<template>
    <!--  -->
   
    <section
        class="blockElement"
        v-show="Object.keys(store.traderDetail).length && store.traderDetail.trader && Object.keys(store.traderDetail.trader).length && store.traderDetail.trader.stats && Object.keys(store.traderDetail.trader.stats).length"
    >
        <div>
            <div class="rightTradeChart position-relative" style="height:200px;">
                <div class="bar-chart position-relative">
                    <Loader :classname="'innerLoader'" v-if="store.singleLoading"></Loader>
                    <Profit ref="profit" />
                </div>
            </div>
        </div>

        
    </section>
</template>
<script>
    import Profit from "@/components/charts/mobile-chart";
    import { myStore } from "@/store/pinia-store";
    export default {
        setup() {
            const store = myStore();
            return { store };
        },
        data() {
            return {
                tab: 1,
                sharePop: false,
                currencyPair: false,
                datePeriod: false,
                dateDrop: [
                    { key: 1, value: "1D" },
                    { key: 3, value: "3D" },
                    { key: 7, value: "7D" },
                    { key: 30, value: "1M" },
                    { key: 90, value: "3M" },
                    { key: 180, value: "6M" },
                    { key: 365, value: "1Y" },
                    { key: 10000, value: "Overall" },
                ],
                dateDropSelected: { key: 10000, value: "Overall" },
                currencyFilter: {},
                currencySearch: "",
                followerInfo: {},
                searchTrder: "",
                show: "less",
                tradingprofitTab: "",
                charttab: 1,
                divHeigh: "",
                showLoginPopup: false,
                copyStrategy: false,
            };
        },
        components: {
            // RealInverstors
            Profit,
           
        },
        watch: {
            "$route.params.id"() {
                if (this.$route.params.id) {
                    this.calltraderDetail();
                }
            },
            "store.traderDetail"() {
                if (Object.keys(this.store.traderDetail).length > 0) {
                    setTimeout(() => {
                        this.$refs.profit.tradingprofitTab = this.store.traderDetail.trader.stats.profile.baseCurrencyName;
                    }, 300);
                }
            },
        },
        methods: {
            changeChartTab(val) {
                this.charttab = val;
            },
            Charttab(type) {
                this.tradingprofitTab = type;
            },
            searchTraders() {
                if (this.searchTrder) {
                    this.store.callFollowersSearchList({}, false, this.searchTrder);
                }
            },
            ClickCurrency(item) {
                if (this.currencyFilter[item]) {
                    delete this.currencyFilter[item];
                } else {
                    this.currencyFilter[item] = item;
                }
                if (this.tab == 1) {
                    this.$refs.profit.getTradingProfitChartData();
                } else if (this.tab == 2) {
                    setTimeout(() => {
                        this.$refs.trading.pageListing();
                    }, 300);
                }
            },
            changeRoute() {
                let path = `/chart/${this.$route.params.id}`;
                this.$router.replace({ path: path, query: { t: this.dateDropSelected.key} });
            },
            calltraderDetail() {
                // this.changeRoute();
                this.store.calltraderDetail({}, true, this.$route.params.id, this).then(() => {
                    if(Object.keys(this.store.traderDetail).length && this.store.traderDetail.trader && this.store.traderDetail.trader.stats){
                        this.$refs.profit.tradingprofitTab = this.store.traderDetail.trader.stats.profile.baseCurrencyName;
                        this.$refs.profit.getTradingProfitChartData();
                    }
                });
            },
        },
        created() {
            if (this.$route.query.t) {
                this.dateDrop.forEach((val) => {
                    if (val.key == parseInt(this.$route.query.t)) {
                        this.dateDropSelected = val;
                    }
                });
            }else{
                this.dateDropSelected = { key: 10000, value: "Overall" }
            }

            //console.log(this.$route.fullpath)
            this.store.$patch({'singleLoading' : true})
            this.calltraderDetail();
        },
    };
</script>
